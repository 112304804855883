

export const AndroidDwonlaod  = () => {

  return (<div style={{ background: "#FFF", width: "100%", height: '100%', padding: "20px" }}>
    <h3 className=" text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">安卓下载安装</h3>
    <p className="mt-2 text-base leading-9 text-2xl text-gray-600">
      点击安卓下载按钮<br />
      等待APK下载完成<br />
      完成后进行安装<br />
    </p>
    <br />
    <div className="p-2">
    <p><span className="text-black  text-2xl" >我已知晓,确认下载</span></p>
    <br />
    <p><a target="_blank" className="text-black rounded-full px-4 py-2 bg-gray-200 text-2xl" href="https://sheapp.s3.ap-southeast-1.amazonaws.com/TA.apk">点击下载</a></p>
    </div>
  </div>)
}