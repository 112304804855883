
export default function Home({ isActive }) {
    return (<div className={"swiper-layout page-home"}>
        <div className={'home-1'}>
            <div className="home-title">
                <img src={require("./title.png")} />
            </div>
            <div>
                <div className="home-telegram">
                    <a href="https://t.me/+hmr0EBE9P-dhMTll" target="_blank">
                        <img src={require("./telegram.png")} />
                    </a>
                </div>
            </div>
        </div>
        <div className="flex-1">
            <div className={"home-box-1"}></div>
            <div className={`home-2 ${isActive ? "animate__animated animate__delay animate__fadeInUp" : ""}`}>
                <img src={require("./title1.png")} />
            </div>

            <div className={"home-box-2"}></div>

            <div className={`home-3 ${isActive ? "animate__animated animate__delay-1-5s animate__fadeInUp" : ""} `}>
                <a href="/android" target="_blank">
                    <img src={require("./android_downlaod.png")} />
                </a>
                <div style={{ width: "30px" }}></div>
                <a href="/ios" target="_blank">
                    <img src={require("./ios_download.png")} />
                </a>
            </div>

            <div className={"home-box-2 text-white mt-10 animate__animated animate__delay-1-5s animate__fadeInUp"}>
                <a href="/apply" className="text-2xl">商家入驻</a>
            </div>

        </div>
    </div>)
}