import "./page.css"
import "./page1.css"
import DownloadButton from "../../widget/button1";


function Page1({ isActive }) {
    return (<div className={"swiper-layout page-1 "}>
        <div className="sp0"></div>
        <div className={`b1 ${isActive? "animate__animated  animate__delay-3s animate__fadeInUp" : ""} `}>
            <img src={require("./b1.png")} alt={""}/>
        </div>
        <div className={`b2 ${isActive? "animate__animated  animate__delay-3s animate__fadeInUp" : ""} `}>
            <img src={require("./b2.png")} alt={""}/>
        </div>
        <div className={`p1 ${isActive? "animate__animated  animate__delay animate__fadeInUp" : ""} `}>
            <img src={require("./p1.png")} alt={""}/>
        </div>
        <div className="sp1"></div>
        <div className={`p2 ${isActive? "animate__animated  animate__delay-1s animate__fadeInUp" : ""}`}>
            <img src={require("./p2.png")} alt={""}/>
        </div>
        <div className="sp2"></div>
        <div className={`p3 ${isActive? "animate__animated  animate__delay-1-5s animate__fadeInUp" : ""} `}>
            <img src={require("./p3.png")} alt={""}/>
        </div>
        <div className={`p6 ${isActive? "animate__animated  animate__delay-2s animate__fadeInUp" : ""}`}>
            <img src={require("./p6.png")} alt={""}/>
        </div>
        <DownloadButton isShowDown={true}/>
    </div>);
}

export default Page1;