import start from "../apply/images/1.png"

export const ApplyDocument = () => {
  return (<div style={{ background: "#FFF", width: "100%", height: '100%', padding: "15px" }}>
    <h3 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">商家入驻</h3>
    <h4 className="text-base font-semibold leading-7 text-gray-900">方式1：</h4>
    <p className="mt-2 text-base leading-7 text-gray-600">
      App内认证（推荐自聊老师）<br />
      打开APP，点击注册/登录  输入账户密码  点击注册/登录按钮<br />
      点击左上角头像，进入个人中心<br />
      找到其他功能中的“成为商家” 点击进入<br />
      请按照app提示录制认证视频(视频只用于认证，不会展示，除工作人员外都不可见)<br />
      提交审核后等待管理员审核通过<br />
    </p>
    <h4 className="text-base font-semibold leading-7 text-gray-900">方式2：</h4>
    <p className="mt-2 text-base leading-7 text-gray-600">
      联系工作人员 （推荐大型商务）<br />
      私聊
      <ul>
        <li> <a className="text-blue-500" href="https://t.me/Taoxiaomi668" target="_blank">https://t.me/Taoxiaomi668</a></li>
        <li><a className="text-blue-500" href="https://t.me/iuthy9988888" target="_blank">https://t.me/iuthy9988888</a></li>
      </ul>
      进群
      <ul>
        <li><a className="text-blue-500" href="https://t.me/tadebug" target="_blank">https://t.me/tadebug</a></li>
      </ul>
      <br />
    </p>
    <h3 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">商家入驻</h3>
    <p className="mt-2 text-base leading-7 text-gray-600" >
    商家入驻审核通过后，默认会有一次发帖机会，如需更多发帖机会请联系管理或者客服。<br />
    首次入驻默认为未交保证金，发帖时请选择怡红院区域发帖，如需要进入桃花楼发帖请联系客服缴纳保证金。<br />
    客户会通过聊天进行约课，请注意查收消息。（目前消息通知功能未开发完全，所以可能会出现收到消息但未提醒的情况）<br />
    <img src={start} />
    商家（老师）可以在聊天框内点击左下角“+”号，选择创建订单，将订单发送给客户。 订单金额可以自定义。 用户付款后在用户端会产生一个核销码，等待客户到达线下后商家可以使用商家端进行扫码核销，核销后资金就会进入钱包。
    （此功能设计初衷是为了保护商家和客户的安全，不需要带现金也不需要转账。同时也给客户安全感，因为在商家核销前用户是可以退款的。切记，开始服务前记得核销）<br />
    服务完成后可以向客户要个好评，好评直接影响排名权重。<br />
    </p>
  </div>)
}