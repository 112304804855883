import './index.css'

export default function DownloadButton({ isShowDown = false }) {

    // 获取 平台 ios / android
    const ua = navigator.userAgent.toLowerCase();
    const isIOS = !!ua.match(/\(i[^;]+;( u;)? cpu.+mac os x/);

    return <div >
        <div className="sp3" ></div>
        {
            isIOS ? <div className="p4 animate__animated animate__fadeInUp">
                <a href='/ios' target='_blank'>
                    <img src={require("./p4.png")} />
                </a>
            </div> : <div className="p4 animate__animated animate__fadeInUp">
                <a href='/android' target='_blank'>
                    <img src={require("./p4.png")} />
                </a>
            </div>
        }
        <div className="sp4"></div>
        {isShowDown && <>
            <div className="p5 animate__animated animate__fadeInUp">
                <img src={require("./p5.png")} />
            </div>
            <div className="sp5"></div>
        </>
        }
    </div>
}