import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AndroidDwonlaod } from './pages/help/android';
import { IosDocument } from './pages/help/ios';
import { ApplyDocument } from './pages/help/apply';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />
  }, {
    path: "/android",
    element: <AndroidDwonlaod />
  }, {
    path: "/ios",
    element: <IosDocument />
  }, {
    "path": "/apply",
    element: <ApplyDocument />
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
