import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, EffectFade } from 'swiper/modules';
import Page1 from '../pages/p1/page1';
import {Page2} from "../pages/p2/page2";
import {Page3} from "../pages/p3/page3";
import Home from "../pages/home/page3";

import 'animate.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css'
import {useState} from "react";

function App() {
  const [activePage, setActivePage] = useState(0);

  const  onReachEnd = () => {
    document.querySelectorAll('.swiper-slide').forEach((slide) => {
      slide.classList.add('animate');
    });
  }


  const onSlideChange = (swiper) => {
    setActivePage(swiper.activeIndex);
  };

  return (
    <Swiper
      direction={'vertical'}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination, Navigation, EffectFade ]}
      onReachEnd={onReachEnd}
      onSlideChange={onSlideChange}
      className="swiper-layout"
    >
    <SwiperSlide>
      <Home isActive={activePage === 0}  />
    </SwiperSlide>
      <SwiperSlide>
        <Page1  isActive={activePage === 1}  />
      </SwiperSlide>
      <SwiperSlide>
        <Page3 isActive={activePage === 2}  />
      </SwiperSlide>
      <SwiperSlide>
        <Page2  isActive={activePage === 3}  />
      </SwiperSlide>
    </Swiper>
  );
}

export default App;
