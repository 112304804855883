import DownloadButton from "../../widget/button1";

export function Page3({isActive}) {
    return (<div className={"swiper-layout page-2"}>
        <div className="sp0"></div>
        <div className={`p1 ${isActive ? "animate__animated  animate__fadeInUp" : ""}`}>
            <img src={require("./p1.png")}/>
        </div>
        <div className="sp1"></div>

        <div className={`p2 ${isActive ? "animate__animated animate__delay-1s animate__fadeInUp" : ""}`}>
            <img src={require("./p2.png")}/>
        </div>
        <div className={"box-p3"}>
            <div className={`p10 ${isActive ? "animate__animated animate__delay-1-5s animate__fadeInUp" : ""}`}>
                <img src={require("./p3.png")}/>
            </div>
            <div className={`p11 ${isActive ? "animate__animated animate__delay-1s animate__fadeInUp" : ""}`}>
                <img src={require("./p4.png")}/>
            </div>
            <div className={`p12 ${isActive ? "animate__animated animate__delay-2s animate__fadeInUp" : ""}`}>
                <img src={require("./p5.png")}/>
            </div>
        </div>
        <div className="flex-1"></div>
        <DownloadButton isShowDown={true}/>
    </div>)
}